@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@layer base {
  body {
    @apply bg-stone-100;
  }
  /* input[type="tel"],
  input[type="text"],
  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  textarea {
    font-size: 16px!important;
  } */
}
@tailwind components;
@tailwind utilities;
@layer utilities {
  .text-shadow-tiny {
    text-shadow: 2px 2px #1c1917;
  }
  .text-shadow-sm {
    text-shadow: 10px 10px #1c1917;
  }
  .text-shadow-md {
    text-shadow: 20px 20px #1c1917;
  }
}