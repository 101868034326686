.w-md-editor-toolbar ul {
  @apply my-auto !important;
  @apply flex-row;
  @apply flex;
}
.w-md-editor-toolbar li > button.save-comment {
  @apply font-sans;
  @apply btn;
  @apply btn-primary;
  @apply rounded-2xl;
  @apply normal-case;
  min-height: 1.5rem;
  height: 1.5rem;
}

.w-md-editor-toolbar li > button {
  /* @apply w-full !important; */
  @apply h-full !important;
  @apply mx-1 !important;
}

.w-md-editor-toolbar li button svg {
  height: 1rem;
  width: 1rem;
  @apply mx-auto !important;
}

.w-md-editor-toolbar li > button.save-comment:hover {
  @apply font-sans;
  @apply btn;
  @apply btn-primary;
  @apply rounded-2xl;
  @apply normal-case;
  min-height: 1.5rem;
  height: 1.5rem;
}

.w-md-editor-toolbar li > button.save-comment:active {
  @apply font-sans;
  @apply btn;
  @apply btn-primary;
  @apply rounded-2xl;
  @apply normal-case;
  min-height: 1.5rem;
  height: 1.5rem;
}

.w-md-editor-toolbar li > button.save-comment:focus {
  @apply font-sans;
  @apply btn;
  @apply btn-primary;
  @apply rounded-2xl;
  @apply normal-case;
  min-height: 1.5rem;
  height: 1.5rem;
}

.eq .w-md-editor-text {
  @apply font-sans;
  @apply text-base
}

.eq .w-md-editor-text textarea {
  @apply font-sans;
  @apply text-base
}

.eq .w-md-editor-text-pre > code {
  @apply font-sans !important;
  @apply text-base !important;
  @apply text-base !important;
}

.w-md-editor-toolbar li .emoji button {
  height: inherit;
}

.w-md-editor-toolbar-child {
  @apply rounded-none !important;
  @apply shadow-none !important;
}